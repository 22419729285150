/**
 * Configurator
 *
 * This is for product configurator section.
 * You can require it on the page using <div data-component="configurator"></div>
 */
import $ from 'jquery';

$('[data-component="configurator"]').each(function () {

    var $config_window = $('.js-product-config'), $config_product = $('.js-tc-product'),
        $sidebar = $('.tc-totals-form.tc-show'), $window = $config_window, offset = $sidebar.offset(),
        topPadding = 0;

    $('.js-config-button').on('click', function (event) {
        event.preventDefault();

        $config_window.fadeIn();
        $('body').addClass('is-active');
        loadStoredToCookie();
        loadInputsFromCookie();
    });

    $('.js-config-back').on('click', function (event) {
        event.preventDefault();

        $config_window.fadeOut();
        $('body').removeClass('is-active');
    });

    $('.tm-totals-form-main').prepend($config_product);

    var isDisabled = $('.single_add_to_cart_button').prop('disabled');

    if (isDisabled) {
        $(this).html('Je configuratie is nog niet compleet');
    }

    $('.js-product-config input').on('change', function (event) {
        let target = event.target;
        storeInputInCookie(target.name, target.value);
    })

    $('form.cart').on('submit', clearCookie);

    window.showSaveConfigurationPopup = showSaveConfigurationPopup;
    window.submitSaveConfigurationPopup = submitSaveConfigurationPopup;
    window.closeEmailConfigurationSendPopup = closeEmailConfigurationSendPopup;

    $('#email-configuration-popup').on('click', function (event) {
        if (event.target.id === 'email-configuration-popup') {
            cancelSaveConfigurationPopup();
        }
    });

    $('#email-configuration-popup .close').on('click', function () {
        cancelSaveConfigurationPopup();
    });

    if (window.stored_config !== undefined) {
        $('.js-config-button').click();
    }

    // old when using TM plugin:
    // $window.scroll( function() {
    // 	if ( $window.scrollTop() > offset.top ) {
    // 		$sidebar.stop().animate( {
    // 			marginTop: $window.scrollTop() - offset.top + topPadding
    // 		} );
    // 	} else {
    // 		$sidebar.stop().animate( {
    // 			marginTop: 0
    // 		} );
    // 	}
    // } );

});

function getCookieName() {
    return 'configurator-inputs';
}

function getCookieAttributes() {
    return {
        secure: true, sameSite: 'strict', expires: 365 // days
    }
}

function getProductId() {
    return $('.js-product-config input[name="product_id"]').val();
}

function loadStoredToCookie() {
    if (window.stored_config === undefined) {
        return;
    }

    for (const key in window.stored_config) {
        storeInputInCookie(key, window.stored_config[key]);
    }
}

function loadInputsFromCookie() {
    const inputs = getAllInputsFromCookie();

    for (const name in inputs) {
        const value = inputs[name];

        const foundInputs = $('.js-product-config input[name="' + name + '"]');

        foundInputs.each(function () {
            const input = $(this);
            if (input.prop('type') === 'radio') {
                if (input.prop('value') === value) {
                    input.trigger('click');
                }
            } else {
                input.val(value);
            }
        });
    }
}

function storeInputInCookie(name, value) {
    let inputMapping = getAllInputsFromCookie();

    inputMapping[name] = value;

    storeInput(inputMapping);
}

function getAllInputsFromCookie() {

    let productInputMapping = getAllProductInputs();
    let inputMapping = {};

    if (getProductId() in productInputMapping) {
        inputMapping = productInputMapping[getProductId()];
    }

    return inputMapping;
}

function getAllProductInputs() {
    let productInputMapping = {};

    const inputString = Cookies.get(getCookieName());
    if (inputString !== undefined) {
        productInputMapping = JSON.parse(inputString);
    }

    return productInputMapping;
}

function getConfigurationHtml() {
    return $('.order-summary .configurator__totals').prop('outerHTML');
}

function sendConfigurationToMail(email) {
    const inputs = getAllInputsFromCookie();
    const formData = {
        'action': 'ds_send_configurator_mail',
        'inputsString': inputs,
        'email': email,
        'product_id': getProductId(),
        'html': getConfigurationHtml()
    }

    $.ajax({
        type: "post", dataType: "json", url: window.configurator_ajax_url, data: formData, success: function (msg) {
            console.log(msg);
        }
    })
}

function submitSaveConfigurationPopup() {
    const email = $('#email-configuration-popup input[name=email]').val();
    const error = $('#email-configuration-popup #email-error');

    if (email === undefined || email === null || email.length === 0) {
        error.text('Je moet een e-mailadres invullen');
        error.show();
        return;
    } else {
        error.hide();
    }

    sendConfigurationToMail(email);

    cancelSaveConfigurationPopup();
    openEmailConfigurationSendPopup();
}

function openEmailConfigurationSendPopup() {
    $('#email-configuration-send-popup').show();
}

function closeEmailConfigurationSendPopup() {
    $('#email-configuration-send-popup').hide();
}

function showSaveConfigurationPopup() {
    $('#email-configuration-popup').show();
}

function cancelSaveConfigurationPopup() {
    $('#email-configuration-popup').hide();
}

function storeInput(inputMapping) {
    let fullMapping = getAllProductInputs();
    fullMapping[getProductId()] = inputMapping;
    Cookies.set(getCookieName(), JSON.stringify(fullMapping), getCookieAttributes());
}

function clearCookie() {
    let fullMapping = getAllProductInputs();
    delete fullMapping[getProductId()];
    Cookies.set(getCookieName(), JSON.stringify(fullMapping), getCookieAttributes());
}