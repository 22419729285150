/**
 * FAQ
 *
 * This is for faq flexible content section.
 */

import $ from 'jquery';

$(function () {
    $('.faq__question').click(function () {
        $(this).siblings('.faq__answer').slideToggle(300);
        $(this).find('.fa-chevron-down').toggle();
        $(this).find('.fa-chevron-up').toggle();
    });
});

