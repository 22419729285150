/**
 * Slider
 *
 * This component requires jQuery and Slick to function.
 * You can require it on the page using <div data-component="slider"></div>
 */
import $ from 'jquery';
import 'slick-carousel';

$(function () {
    $('[data-component="slider"]').each(function () {
        var $holder = $(this);

        $holder.slick(
            {
                rows: 0,
                dots: true,
                autoplay: true,
                arrows: false,
                autoplaySpeed: 5000,
                infinite: true
            }
        );
    });
});
