/**
 * Main
 *
 * The main file, page logic which is needed on all pages will be included here.
 * Please note for specific components like a slider create a separated components/<component_name>.js
 * The components will be loaded through the component loader and are not needed on every page!
 */

import $ from 'jquery';
import 'jquery.mmenu';

function switchChatInfo(status) {
    var chatInfoBlock = $('[data-contact="contact"]');
    if (status === 'offline') {
        chatInfoBlock.hide()
    } else {
        chatInfoBlock.show();
    }
}

$(function () {

    if (typeof zE !== 'function') {
        switchChatInfo('offline');
    } else {
        zE('webWidget:on', 'chat:status', switchChatInfo);
    }

    $('#nav').mmenu(
        {
            scrollBugFix: true,
            offCanvas: {
                position: 'right'
            },
            navbar: {
                title: ''
            }
        }, {
            clone: true
        }
    );

    $('a[href="#top"]').on(
        'click', function (event) {
            event.preventDefault();

            $('html, body').animate({scrollTop: 0});
        }
    );

    $(window).scroll(function () {
        if ($(this).scrollTop() > 40) {
            $('.header').addClass('header--fixed');
            $('.notification-popup').addClass('notification-popup--up');
            $('.header--notification').addClass('header--up');
        } else {
            $('.header').removeClass('header--fixed');
            $('.notification-popup').removeClass('notification-popup--up');
            $('.header--notification').removeClass('header--up');
        }
    });

    var $mega = $('nav li.mega-menu a');
    $($mega).click(function () {
        $mega.next().toggleClass('open');
        $mega.toggleClass('js-arrow');
    });

    $(document).mouseup(function (e) {
        var container = $mega;

        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.next().removeClass('open');
            container.removeClass('js-arrow');
        }
    });

    $('.products__list').find('nav').prev().addClass('js-space');
    $('.header__right nav > ul > li').find('ul').prev().addClass('menu-parent');

    $('.read-more').click(function () {
        $(this).siblings('.more-text').contents().unwrap();
        $(this).remove();
    });

    $('.js-toggle-title').click(function () {
        $(this).next().slideToggle();
        $(this).toggleClass('is-open');
        $(this).parents('.specification-tab__repeat').siblings().find('.specification-tab__toggle').slideUp();
        $(this).parents('.specification-tab__repeat').siblings().find('.js-toggle-title').removeClass('is-open');
    });

    $('.js-notify').click(function () {
        $('.notification-popup').hide();
        $('.wrapper').removeClass('wrapper--notification');
        $('.header').removeClass('header--notification');
    });
});

